<template>
  <section>
    <h1>
      {{ $t('survey.list.title') }}
      <span v-if="!checkStatus('PAT')">({{ this.user.username }})</span>
    </h1>

    <v-progress-circular indeterminate color="primary" v-if="loading"/>

    <p v-else-if="!loading && noSurveyToday">
      {{ $t('survey.list.noSurveyToday') }}
    </p>

    <div v-else-if="!loading && !noSurveyToday" ref="surveyList">
      <article
          v-for="(survey, index) in localeSurveys"
          :key="index"
          :class="{ complete: survey.status === 2, partial: survey.status === 1 }"
          ref="surveyListItem"
      >
        <router-link :to="{ name: 'Survey', params: { surveyId: survey.id, userId, day: survey.day } }"
                     ref="surveyListItemLink"
        >
          <h2>{{ survey.date.toLocaleDateString() }} - {{ survey.label }}</h2>
        </router-link>
      </article>
    </div>
    <v-btn :to="{ name: 'DashboardPatient', params: { patientId: userId } }"
           color="primary"
           depressed
           v-if="!isPatient"
    >
      {{ $t('survey.actions.buttons.back') }}
    </v-btn>
    <v-btn :to="{ name: 'Dashboard', params: { patientId: userId } }"
           color="primary"
           depressed
           v-else
    >
      {{ $t('survey.actions.buttons.back') }}
    </v-btn>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { myStatus } from '@/store/modules/User'

export default {
  name: 'SurveyListDay',

  props: {
    userId: {
      required: true,
    },
    surveyCat: {
      required: true,
    },
  },

  data: () => ({
    loading: false,
    localeSurveys: [],
    noSurveyToday: false,
    today: null,
  }),

  computed: {
    ...mapGetters([
      'isPatient',
      'user',
      'userConnected',
      'userSurveys',
    ]),
  },

  mounted () {
    this.loading = true
    this.loadUserSurveys(this.userId)
        .then(() => {
          if (this.userConnected) {
            if (this.userConnected.groups.some(group => group.name === 'PAT')) {
              this.today = this.userConnected.currentStudyDay
              this.initLocaleSurveys()
            } else {
              this.loadUser({ id: this.userId })
                  .then(() => {
                    this.today = this.user.currentStudyDay
                    this.initLocaleSurveys()
                  })
            }
          }
        })
  },

  methods: {
    ...mapActions([
      'loadUser',
      'loadUserSurveys',
      'loadUserConnected',
    ]),
    initLocaleSurveys () {
      for (const userSurvey of this.userSurveys) {
        if (userSurvey.day >= this.today) {
          if (userSurvey.day === this.today) {
            userSurvey.surveys.forEach(survey => {
              if (survey.category === this.surveyCat) {
                this.localeSurveys.push({
                  ...survey,
                  day: userSurvey.day,
                  date: new Date(),
                })
              }
            })
          }
          break
        } else {
          if (userSurvey.surveys.some(survey => survey.status === 0 || survey.status === 1)
            || this.userConnected.groups.some(group => group.name === 'TEC')) {
            const dayDate = new Date((new Date()).setDate((new Date).getDate() - (this.today - userSurvey.day)))
            userSurvey.surveys.forEach(survey => {
              if (survey.category == this.surveyCat) {
                this.localeSurveys.push({
                  ...survey,
                  day: userSurvey.day,
                  date: dayDate,
                })
              }
            })
          }
        }
      }

      this.noSurveyToday = this.localeSurveys.length === 0
      this.loading = false
    },
    checkStatus (status) {
      return myStatus([status])
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/style/formSurveyList";
</style>
